
.MuiAppBar-colorPrimary {
  background-color: #000000ee !important;
  border-bottom: 1px solid #FFF3;
}


.linkDestyle, .linkDestyle:visited, .linkDestyle:active {
  text-decoration: none;
  color: inherit;
}

.footerClass {
  min-height: 250px;
  border-top: 1px solid #FFF3;
  padding: 15px;
  display: flex;
  justify-content: space-evenly;
}

.copyright {
  padding: 20px;
  text-align: center;
}

.foodName {
  color: rgb(206, 171, 97);
  padding: 10px;
  font-weight: bold;
}

.foodDesc {
  padding: 10px;
  padding-bottom: 25px;
  text-align: center;
  width: 100%;
}

.copperColor {
  color: rgb(206, 171, 97);
}

.p10 {
  padding: 10px;
}

.headerBanner {
  display: flex;
  padding: 20px;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 30px;

  box-shadow: 2px 2px 2px 2px #333;
  background-color: #ceab61;
  color: #000;
}

.bigText {
  font-size: 1.5em;
  font-weight: bold;
}

.p5 {
  padding: 5px;
}

.gridFlex {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}

.smallText {
  font-size: 0.9em;
}

.sanantonio {
  background-color: #000;
  color: #fff;
}

.sanantoniotext {
  color: #fff;
  background-color: #000;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
  margin-top: 0;
}

.MuiMenu-list {
 
  color: #fff;
  text-transform: uppercase;
  padding: 0px !important;
}

.MuiPaper-root {
  border-radius: 0px !important;
}

#menu-appbar .MuiPaper-root  {
  background-color: #000000ee;
  padding-bottom: 1px;
  margin-top: 5px;
  border-top: 0px !important;
  border-right: 1px solid #777 !important;
  border-bottom: 1px solid #777 !important;
  border-left: 1px solid #777 !important;

  border-bottom-right-radius: 15px !important;
}

ul.MuiMenu-list li {
  border-bottom: 1px solid #ceab61;
  min-width: 200px;
  padding-bottom: 10px;
  padding-top: 10px;
}


.gridBottom {
  display: flex;
  justify-content: center;
  flex-direction: column !important;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
}

.gridPicture {
  max-width: 300px;
}


.invertColors {
  background-color: #ceab61;
  color: #000 !important;
}

.gridHeight {
  height: 390px;
}

.mobileFont {
  letter-spacing: 1px;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}
